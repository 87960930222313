import React, { useEffect } from "react";
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import HotItem from "../components/HotItem/HotItem";
import AssociatedBrand from '../components/AssociatedBrand/AssociatedBrand';
import SectionDivider from "../components/SectionDivider/SectionDivider";
import SubscriptionPlan from "../components/SubscriptionPlan/SubscriptionPlan";


/* import buyMoreBanerImg from '../../utils/assets/home/buy-more-and-better-jouin-us.png';
import saveWhenShoppingBannerImg from '../../utils/assets/home/save-when-shopping.png'; */
import shoppigBagsIcon from '../../utils/assets/home/icon/shopping-bags-icon.png';
import shippingIcon from '../../utils/assets/home/icon/shipping-icon.png';
import saleIcon from '../../utils/assets/home/icon/sale-icon.png';
import infinitIcon from '../../utils/assets/home/icon/infinit-icon.png';
import platformIcon from '../../utils/assets/home/icon/plataforms-icon.png';
import assistenceIcon from '../../utils/assets/home/icon/assistance-icon.png';


//test
import bgImg1 from '../../utils/assets/home/bg1.png';
// import onbgImg1 from '../../utils/assets/home/onbg1.png';
import onbgImg2 from '../../utils/assets/home/onbg2.png';

import mobileIncredibleOffers from '../../utils/assets/home/mobile-receive-incredible-offers-on-your-mobile.png';
import mobileWellnessOffer from '../../utils/assets/home/mobile-receive-a-discount-for-a-wellness-treatment.png';
import tabletIncredibleOffers from '../../utils/assets/home/tablet-receive-incredible-offers-on-your-mobile.png';
import tabletWellnessOffer from '../../utils/assets/home/tablet-receive-a-discount-for-a-wellness-treatment.png';
import desktopIncredibleOffers from '../../utils/assets/home/desktop-receive-incredible-offers-on-your-mobile.png';
import desktopWellnessOffer from '../../utils/assets/home/desktop-receive-a-discount-for-a-wellness-treatment.png';


const Home = props => {

    useEffect(()=>{
        window.scroll(0,0);
      },[]);
      
     const bannerSectionInfo = [
         {
             cardTitle : "Buy more and better",
             cardButtonText : "Register",
             cardBannerPosition : 1,
             desktopBackground : desktopIncredibleOffers,
             tabletBackground : tabletIncredibleOffers,
             mobileBackground : mobileIncredibleOffers
         },
         {
            cardTitle : "Save when shopping, dining, traveling and more!",
            cardBannerPosition : 2, 
            cardButtonText : "KNOW MORE",
           
            desktopBackground : desktopWellnessOffer,
             tabletBackground : tabletWellnessOffer,
             mobileBackground :  mobileWellnessOffer
            
        }
     ]

     const dividerInfo = [
        {
            sectionName : "",
            icon1 : shoppigBagsIcon,
            description1 : "FREE GIVEAWAYS",
            icon2: shippingIcon,
            description2 : "FREE SHIPPING",
            icon3 : saleIcon,
            description3 : "DAILY DEALS",
            icon4 : infinitIcon,
            description4 : "DOES NOT EXPIRE",
            icon5 : platformIcon,
            description5:"CROSS-PLATFORM",
            icon6 : assistenceIcon,
            description6 : "24 / 7 / 365 ASSISTANCE"

        }
     ]

     const subscriptionCardInformation = [
        {
            subscriptionType : "TRY",
            subscriptionPrice: "3.49",
            subscriptionTrialInfo : "Trial for 21 days",
            subscriptionTrialPrice: "",
            subscriptionButtonText: "TRY NOW"
        },
        {
            subscriptionType : "MONTH",
            subscriptionPrice: "41.27",
            subscriptionTrialInfo: "Monthly",
            subscriptionTrialPrice: "",
            subscriptionButtonText: "Buy"
        }
    ]

    return(
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo[0]}/>
            <HotItem/>
            <SectionDivider props={dividerInfo[0]}/>
            <WelcomeSection props={bannerSectionInfo[1]}/>
            <SubscriptionPlan props={subscriptionCardInformation}/> 
            <AssociatedBrand/>
            
        </React.Fragment>
    );
};

export default Home;