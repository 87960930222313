import React, { useState } from "react";

import './AvailablePlan.css';

import PriceCard from '../../../shared/components/PriceCard/PriceCard';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const AvailablePlan = props => {

    const subscriptionCardInformation = [
        {
            subscriptionType: "try",
            subscriptionPrice: "3.49",
            subscriptionDescription: "Trial period of 21 days",
            subscriptionButtonText: "TRY NOW",
            subscriptionDefinition: "Please have a look at the terms and conditions and contact us if you have any questions or concerns. Your monthly membership fee will be automatically billed to your designated billing source at the end of the period. If you wish to cancel the service, remember to notify us of your decision by calling or completing the cancellation form."
        },
        {
            subscriptionType: "month",
            subscriptionPrice: "41.27",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy",
            subscriptionDefinition: "Please have a look at the terms and conditions and contact us if you have any questions or concerns. Your monthly membership fee will be automatically billed to your designated billing source at the end of the period. If you wish to cancel the service, remember to notify us of your decision by calling or completing the cancellation form."
        }
    ]
    const [subscriptionCards, setSubscriptionCards] = useState(subscriptionCardInformation);
    return (
        <section className="AvailablePlan__main-section-container">
            <div className="AvailablePlan__title-container">
                <h1 className="AvailablePlan__title">Choose how you want to register</h1>
            </div>
            <div className="AvailablePlan__components-container" >
                <div className="AvailablePlan__subscriptionCard" >
                    {
                        subscriptionCards.map((subscriptionCard, index) => (
                            <PriceCard key={index} props={subscriptionCard} />
                        ))
                    }
                </div>
                <div className="AvailablePlan__contact-card-container">
                    <ContactCard />
                </div>
            </div>
        </section>
    );
};

export default AvailablePlan;